import React, { useEffect, useRef, useState } from 'react'
import styles from './Form.module.css'
import { toast } from 'react-toastify'
import { toEmail, url } from '../../Constants/const'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Radio,
  RadioGroup,
} from '@nextui-org/react'
import { useForm, Controller } from 'react-hook-form'
import { Toastify } from '../../Components/Toastify/Toastify'
import { baseURL, chatId, validator } from '../../Constants/urls'

// UTM parameter retrieval function
const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    utm_source: urlParams.get('utm_source') || '',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_content: urlParams.get('utm_content') || '',
  }
}

const Form = () => {
  const [selectedKeys, setSelectedKeys] = useState(new Set())
  const [maxLength, setMaxLength] = useState(50)
  const toastId = useRef(null)
  const [phone, setPhone] = useState('')
  const [utmTags, setUtmTags] = useState({}) // State to store UTM parameters

  const { control, register, handleSubmit, reset, formState: { errors } } = useForm()

  const selectedValue = React.useMemo(
    () =>
      Array.from(selectedKeys)
        .map((key) => key.replace('_', ' '))
        .join(', '),
    [selectedKeys],
  )

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const updateMaxLength = () => {
    const width = window.innerWidth
    if (width <= 720) {
      setMaxLength(25)
    } else if (width <= 1440) {
      setMaxLength(50)
    } else {
      setMaxLength(100)
    }
  }

  useEffect(() => {
    updateMaxLength()
    window.addEventListener('resize', updateMaxLength)

    // Retrieve UTM parameters when the component mounts
    setUtmTags(getUTMParams())

    return () => {
      window.removeEventListener('resize', updateMaxLength)
    }
  }, [])

  const onSubmit = async (formData) => {
    toastId.current = toast(<Toastify type={'pending'} />, {
      autoClose: 3000,
      hideProgressBar: true,
    })

    const data = {
      name: formData.fullName,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
      comment: `Address - ${formData.address} \n Contact method - ${
        formData.contactMethod
      } \n Services - ${Array.from(selectedKeys).join(', ')}`,
      to: toEmail,
    }

    console.log('Form Data:', data)

    if (formData.fullName && !errors.email) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })

        if (response.ok) {
          toast.update(toastId.current, {
            render: <Toastify type={'success'} />,
            type: 'success',
            icon: false,
            autoClose: 3000,
            hideProgressBar: false,
          })
        } else {
          toast.update(toastId.current, {
            render: 'Something went wrong. Please refresh the page and try again',
            type: 'error',
            autoClose: 7000,
          })
        }

        const responseData = await response.json()
        console.log('Success:', responseData)
        reset()
        setPhone('')

        // Telegram message
        const telegramMessage = `
📬 New Form Submission:
Full Name: ${formData.fullName}
Phone: ${formData.phone}
Email: ${formData.email}
Address: ${formData.address}
Contact Method: ${formData.contactMethod}
Services: ${Array.from(selectedKeys).join(', ')}
UTM Source: ${utmTags.utm_source}
UTM Campaign: ${utmTags.utm_campaign}
UTM Medium: ${utmTags.utm_medium}
UTM Term: ${utmTags.utm_term}
UTM Content: ${utmTags.utm_content}
        `
        await fetch(baseURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            validator: validator,
            chat_id: chatId,
            message: telegramMessage,
          }),
          }
        )
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }


  return (
    <div className={styles.form_bg}>
      <div className={`${'customContainer'} ${styles.form}`}>
        <div className={styles.form_first}>
          <h2 className={styles.title}>Get in Touch</h2>
          <div className={styles.line}></div>
          <p className={styles.desc}>
            Whether you have questions, need a quote, or are ready to start your
            project, we're here to assist you. Fill out this form, and we will
            get in touch with you.
          </p>
        </div>
        <div className={styles.form_second}>
          <form
            action=""
            className={styles.form_wrap}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              {...register('fullName', { required: true })}
              classNames={{
                label: `${styles.input}`,
                input: `${styles.input_bg}`,
                inputWrapper: `${styles.input_bg}`,
              }}
              label="Full Name"
              labelPlacement="outside"
              placeholder="Enter your full name"
              type="text"
            />
            {errors.fullName && (
              <span className={styles.error}>Full Name is required</span>
            )}
            <Input
              {...register('email', { required: true })}
              classNames={{
                label: `${styles.input}`,
                input: `${styles.input_bg}`,
                inputWrapper: `${styles.input_bg}`,
              }}
              label="Email Address"
              labelPlacement="outside"
              placeholder="Enter your email"
              type="email"
            />
            {errors.email && (
              <span className={styles.error}>Email is required</span>
            )}
            <Controller
              name="phone"
              control={control}
              value={phone}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field} // Ensure the field is spread here
                  classNames={{
                    label: `${styles.input}`,
                    input: `${styles.input_bg}`,
                    inputWrapper: `${styles.input_bg}`,
                  }}
                  label="Phone Number"
                  labelPlacement="outside"
                  placeholder="Enter your phone number"
                  type="text"
                />
              )}
            />

            {errors.phone && (
              <span className={styles.error}>Phone Number is required</span>
            )}
            <Input
              {...register('address', { required: true })}
              classNames={{
                label: `${styles.input}`,
                input: `${styles.input_bg}`,
                inputWrapper: `${styles.input_bg}`,
              }}
              label="Property Address"
              placeholder="Enter your address"
              labelPlacement="outside"
              type="text"
            />
            {errors.address && (
              <span className={styles.error}>Address is required</span>
            )}
            <div>
              <div className={styles.dropdown}>
                <span className={styles.dropdown_label}>
                  Service of Interest
                </span>
                <Dropdown>
                  <DropdownTrigger className={styles.dropdown_select}>
                    <Button
                      variant="bordered"
                      className={`${styles.dropdown} ${styles.dropdown_bg}`}
                    >
                      {selectedKeys.size === 0
                        ? 'Select Service'
                        : truncateText(selectedValue, maxLength)}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Multiple selection example"
                    variant="flat"
                    closeOnSelect={false}
                    selectionMode="multiple"
                    selectedKeys={selectedKeys}
                    onSelectionChange={setSelectedKeys}
                  >
                    <DropdownItem key="Siding">Siding</DropdownItem>
                    <DropdownItem key="Windows and Doors">
                      Windows and Doors
                    </DropdownItem>
                    <DropdownItem key="Development/Investments">
                      Development/Investments
                    </DropdownItem>
                    <DropdownItem key="Other">Other</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <Controller
              name="contactMethod"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  label="Preferred Contact Method"
                  orientation="horizontal"
                  classNames={{
                    label: `${styles.input}`,
                    input: `${styles.input_bg}`,
                    inputWrapper: `${styles.input_bg}`,
                  }}
                >
                  <Radio
                    classNames={{
                      label: `${styles.input}`,
                      input: `${styles.input_bg}`,
                      inputWrapper: `${styles.input_bg}`,
                    }}
                    value="phone"
                    color="warning"
                  >
                    Phone
                  </Radio>
                  <Radio
                    classNames={{
                      label: `${styles.input}`,
                      input: `${styles.input_bg}`,
                      inputWrapper: `${styles.input_bg}`,
                    }}
                    value="email"
                    color="warning"
                  >
                    Email
                  </Radio>
                </RadioGroup>
              )}
            />
            {errors.contactMethod && (
              <span className={styles.error}>
                Preferred Contact Method is required
              </span>
            )}
            <Button type="submit" className={styles.submit}>
              Submit
            </Button>
            <p className={styles.privacy}>
              We value your privacy. Your information will be kept confidential
              and will not be shared with third parties.
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
